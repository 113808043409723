.custom-tabs {
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 16px;
  height: 20rem;
}

.custom-tab {
  font-size: 1.2rem;
  font-weight: normal;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: border-bottom-color 0.3s ease-in-out;
  margin-right: 1rem;
}

.custom-tab:hover {
  border-bottom-color: blue;
}

.custom-tab-active {
  font-weight: bold;
  border-bottom-color: blue;
}


